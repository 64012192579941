import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const ApplicationScreens = {
  APPLICATION: {
    PATH: '/app',
    TITLE: 'Application',
    TITLE_ALT: 'Application',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_APPLICATION: {
    PATH: '/app/application/list',
    HEADER_NAME: 'Application',
    TITLE: 'Application List',
    TITLE_ALT: 'List',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_APPLICATION: {
    PATH: '/app/application/list/add',
    TITLE: 'Add New Application',
    TITLE_ALT: 'Add New Application',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_APPLICATION: {
    PATH: '/app/application/list/edit/:id',
    TITLE: 'Edit Application',
    TITLE_ALT: 'Edit Application',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_APPLICATION: {
    PATH: '/app/application/list/detail/:id',
    TITLE: 'Detail Application',
    TITLE_ALT: 'Detail Application',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_VERSION: {
    PATH: '/app/version/list',
    HEADER_NAME: 'Application Version',
    TITLE: 'Application Version List',
    TITLE_ALT: 'Version',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_VERSION: {
    PATH: '/app/version/list/add',
    TITLE: 'Add New Version',
    TITLE_ALT: 'Add New Version',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_VERSION: {
    PATH: '/app/version/list/edit/:id',
    TITLE: 'Edit Version',
    TITLE_ALT: 'Edit Version',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_VERSION: {
    PATH: '/app/version/list/detail/:id',
    TITLE: 'Detail Version',
    TITLE_ALT: 'Detail Version',
    breadcrumbs: defaultBreadcrumbs,
  },
}

export default ApplicationScreens
