import clsx from 'clsx'
import {useEffect, useState} from 'react'
import Select, {ControlProps, GroupBase, SelectComponentsConfig, components} from 'react-select'
import {useLocalizationHook} from 'src/app/plugins/i18n/localization-hook'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'

const Control = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  isFocused,
  isDisabled,
  ...rest
}: ControlProps<Option, IsMulti, Group>) => (
  <components.Control
    className={clsx('shadow-none bg-transparent w-16', {
      focus: isFocused,
      disabled: isDisabled,
    })}
    isFocused
    isDisabled
    {...rest}
  >
    {children}
  </components.Control>
)

const IndicatorSeparator = () => null
const DropdownIndicator = () => null

export const CustomReactSelect = {
  Control,
  IndicatorSeparator,
  DropdownIndicator,
}

const options = [
  {
    label: 'en',
    value: 'en',
    image: toAbsoluteUrl('/media/flags/england.svg'),
  },
  {
    label: 'fr',
    value: 'fr',
    image: toAbsoluteUrl('/media/flags/france.svg'),
  },
  {
    label: 'id',
    value: 'id',
    image: toAbsoluteUrl('/media/flags/indonesia.svg'),
  },
  {
    label: 'ja',
    value: 'ja',
    image: toAbsoluteUrl('/media/flags/japan.svg'),
  },
  {
    label: 'pt',
    value: 'pt',
    image: toAbsoluteUrl('/media/flags/portugal.svg'),
  },
  {
    label: 'ko',
    value: 'ko',
    image: toAbsoluteUrl('/media/flags/south-korea.svg'),
  },
  {
    label: 'de',
    value: 'de',
    image: toAbsoluteUrl('/media/flags/germany.svg'),
  },
]

const formatOptionLabel = (payload: any) => (
  <div className='flex items-center justify-center w-full'>
    <img src={payload.image} alt={payload.label} className='w-8 h-8 p-1 rounded-full ' />
  </div>
)

const HeaderFlagSelect = () => {
  const {translation, setTranslation} = useLocalizationHook()
  const [defaultFlag, setDefaultFlag] = useState<any>('')

  useEffect(() => {
    const flag = options.find((x) => x.value === translation)

    setDefaultFlag(flag?.image)
  }, [translation])

  return (
    <Select
      value={{value: translation, label: translation, image: defaultFlag}}
      className='mx-4 bg-transparent'
      components={CustomReactSelect as SelectComponentsConfig<any, false, any>}
      onChange={(payload: any) => setTranslation(payload.value)}
      options={options}
      formatOptionLabel={formatOptionLabel}
      placeholder=''
      isSearchable={false}
    ></Select>
  )
}

export default HeaderFlagSelect
