import {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import LocalizationRedux from './localization-redux'

export function useLocalizationHook() {
  const dispatch = useDispatch()

  const translation = useSelector<RootState>(
    ({localization}) => localization.translation,
    shallowEqual
  )

  const {t, i18n} = useTranslation()

  const setTranslation = useCallback(
    (val: LocalizationRedux.ITranslationState) => {
      dispatch(LocalizationRedux.actions.setLanguage(val))
      window.location.reload()
    },
    [dispatch]
  )

  useEffect(() => {
    i18n.changeLanguage(translation as string)
  }, [i18n, translation])

  return {t, i18n, translation, setTranslation}
}
