import {FC} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import {FormLabelProps} from './Label.types'

export const FormLabel: FC<FormLabelProps> = ({
  children,
  required,
  optional,
  description,
  className,
}) => {
  return (
    <div className={cn('flex flex-col', className)} data-testid='form-label-container'>
      <label className='flex-grow font-medium text-fs-9 text-neutral-80'>
        {children}
        {required && <span className='ml-1 text-danger'>*</span>}
        {optional && <span className='ml-1 text-neutral-70'>(Opsional)</span>}
      </label>

      {description && (
        <div className='flex items-center mt-1'>
          <label className='text-fs-10 text-neutral-70'>{description}</label>
        </div>
      )}
    </div>
  )
}
