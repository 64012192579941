import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../components/Layout/FallbackView'
import ApplicationScreens from '../modules/application/Screens'
import ClientScreens from '../modules/client/Screens'
import DashboardScreens from '../modules/dashboard/Screens'
import UserRoleScreens from '../modules/permissions/Screens'
import ProfileScreens from '../modules/profile/Screens'

export function PrivateRoutes() {
  const DashboardPage = lazy(() => import('../modules/dashboard/DashboardRoutes'))
  const ApplicationPage = lazy(() => import('../modules/application/ApplicationRoutes'))
  const UserRolePage = lazy(() => import('../modules/permissions/UserRoleRoutes'))
  const ClientPage = lazy(() => import('../modules/client/ClientRoutes'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfileRoutes'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path={DashboardScreens.DASHBOARD.PATH} component={DashboardPage} />
        <Route path={ApplicationScreens.APPLICATION.PATH} component={ApplicationPage} />
        <Route path={UserRoleScreens.USER_ROLE.PATH} component={UserRolePage} />
        <Route path={ClientScreens.CLIENT.PATH} component={ClientPage} />
        <Route path={ProfileScreens.PROFILE.PATH} component={ProfilePage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
