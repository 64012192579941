import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {FC} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {FormLabel, FormPassword, FormText, GAlert, GButton} from 'src/app/components/Libs'
import {useWebTitle} from 'src/app/hooks/title-hook'
import * as Yup from 'yup'
import AuthScreens from '../../Screens'
import AuthRedux from '../../redux/AuthRedux'
import {loginByEmail} from '../../services/Auth.services'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Make sure the email address you entered is correct.')
    .required('Enter your email address'),
  password: Yup.string().required('Enter your password'),
})

const initialValues = {
  email: '',
  password: '',
}

const LoginEmail: FC = () => {
  const dispatch = useDispatch()
  useWebTitle('Login')

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      return loginByEmail(payload)
    },
    onSuccess: (result) => {
      const data = result.data.response_output.detail
      const {token, refreshtoken} = data

      dispatch(AuthRedux.actions.login(token, refreshtoken))
    },
    onError: (e: any) => {
      const status = e.response.status

      if (status >= 500) {
        formik.setStatus('Service Temporarily Unavailable. Please try again later.')
      } else {
        formik.setStatus("We don't recognize that Email Address. Please try again.")
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values) => submitFn.mutate(values),
  })

  return (
    <div data-testid='login-email-page' className='w-full'>
      <div className='mb-12 text-center'>
        <div className='mb-4 font-medium text-fs-5'>Welcome</div>
        <div className='text-neutral-80'>Please Sign In to your account</div>
      </div>

      {formik.status && <GAlert className='mb-8'>{formik.status}</GAlert>}

      <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
        <div className='mb-8'>
          <FormLabel required className='mb-2'>
            Email
          </FormLabel>
          <FormText
            {...formik.getFieldProps('email')}
            name='email'
            type='email'
            placeholder='Email address'
            maxLength={50}
            error={formik.errors.email}
            touched={formik.touched.email}
          />
        </div>

        <div className='mb-8'>
          <FormLabel required className='mb-2'>
            Password
          </FormLabel>
          <FormPassword
            {...formik.getFieldProps('password')}
            name='password'
            placeholder='Password'
            minLength={8}
            error={formik.errors.password}
            touched={formik.touched.password}
          />

          <div className='pb-2 mt-4 font-medium text-end text-primary text-fs-9'>
            <Link to={AuthScreens.FORGOT.PATH}>
              <span className='cursor-pointer'>Forgot Password?</span>
            </Link>
          </div>
        </div>

        <GButton
          type='submit'
          size='large'
          className='w-full'
          loading={submitFn.isLoading}
          disabled={submitFn.isLoading}
        >
          Sign In
        </GButton>
      </form>
    </div>
  )
}

export default LoginEmail
