import {defaultBreadcrumbs, generateBreadcrumb} from 'src/app/models/screen-prop'

const ClientScreens = {
  CLIENT: {
    PATH: '/client',
    TITLE: 'Client',
    TITLE_ALT: 'Client',
    breadcrumbs: defaultBreadcrumbs,
  },
  // USER
  LIST_USER: {
    PATH: '/client/user/list',
    HEADER_NAME: 'Client',
    TITLE: 'Client Users',
    TITLE_ALT: 'Client Users',
    PERMISSION: 'user.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_USER: {
    PATH: '/client/user/list/add',
    TITLE: 'Add New User',
    TITLE_ALT: 'Add New User',
    PERMISSION: 'user.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_USER: {
    PATH: '/client/user/list/edit/:id',
    TITLE: 'Edit User',
    TITLE_ALT: 'Edit User',
    PERMISSION: 'user.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_USER: {
    PATH: '/client/user/list/detail/:id',
    TITLE: 'Detail User',
    TITLE_ALT: 'Detail User',
    PERMISSION: 'user.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  // ROLE
  LIST_ROLE: {
    PATH: '/client/role/list',
    HEADER_NAME: 'Client',
    TITLE: 'Client Roles',
    TITLE_ALT: 'Client Roles',
    PERMISSION: 'user_roles.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_ROLE: {
    PATH: '/client/role/list/add',
    TITLE: 'Add Role',
    TITLE_ALT: 'Add Role',
    PERMISSION: 'user_roles.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_ROLE: {
    PATH: '/client/role/list/edit/:id',
    TITLE: 'Edit Role',
    TITLE_ALT: 'Edit Role',
    PERMISSION: 'user_roles.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_ROLE: {
    PATH: '/client/role/list/detail/:id',
    TITLE: 'Detail Role',
    TITLE_ALT: 'Detail Role',
    PERMISSION: 'user_roles.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  // MEMBER
  LIST_MEMBER: {
    PATH: '/client/member/list',
    HEADER_NAME: 'Member List',
    TITLE: 'Member',
    TITLE_ALT: 'Member List',
    PERMISSION: 'members.read',
  },
  ADD_MEMBER: {
    PATH: '/client/member/list/add',
    HEADER_NAME: 'Add Member',
    TITLE: 'Add Member',
    TITLE_ALT: 'Add Member',
    PERMISSION: 'members.create',
  },
  EDIT_MEMBER: {
    PATH: '/client/member/list/edit/:id',
    TITLE: 'Edit Member',
    TITLE_ALT: 'Edit Member',
    PERMISSION: 'members.update',
  },
  DETAIL_MEMBER: {
    PATH: '/client/member/list/detail/:id',
    TITLE: 'Detail Member',
    TITLE_ALT: 'Detail Member',
    PERMISSION: 'members.read',
  },
  // ORGANIZATION
  LIST_ORGANIZATION: {
    PATH: '/client/organization/list',
    TITLE: 'Organization',
    TITLE_ALT: 'List Organization',
  },
  ADD_ORGANIZATION: {
    PATH: '/client/organization/list/add',
    TITLE: 'Add Organization',
    TITLE_ALT: 'Add Organization',
  },
  EDIT_ORGANIZATION: {
    PATH: '/client/organization/list/edit/:id',
    TITLE: 'Edit Organization',
    TITLE_ALT: 'Edit Organization',
  },
  DETAIL_ORGANIZATION: {
    PATH: '/client/organization/list/detail/:id',
    TITLE: 'Detail Organization',
    TITLE_ALT: 'Detail Organization',
  },
}

const userBreadcrumbs = generateBreadcrumb([ClientScreens.LIST_USER])
const roleBreadcrumbs = generateBreadcrumb([ClientScreens.LIST_ROLE])

ClientScreens.ADD_USER.breadcrumbs = () => userBreadcrumbs
ClientScreens.EDIT_USER.breadcrumbs = () => userBreadcrumbs
ClientScreens.DETAIL_USER.breadcrumbs = () => userBreadcrumbs
ClientScreens.ADD_ROLE.breadcrumbs = () => roleBreadcrumbs
ClientScreens.EDIT_ROLE.breadcrumbs = () => roleBreadcrumbs
ClientScreens.DETAIL_ROLE.breadcrumbs = () => roleBreadcrumbs

export default ClientScreens
