import React from 'react'
import {Card} from 'src/app/components/Libs'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'

const GuestWrapper: React.FC<{withTitle?: boolean; children?: React.ReactNode}> = ({
  withTitle = true,
  children,
}) => {
  return (
    <div
      data-testid='content-wrapper-component'
      className='flex items-center justify-center w-screen h-screen py-24 bg-neutral-10'
    >
      <div className='h-max-[688px] w-[540px]'>
        <Card.Root className='flex items-center justify-between h-full py-16 rounded-lg'>
          <img src={toAbsoluteUrl('/logo_ait.svg')} alt='AIT' className='h-20 mb-16' />

          <div className='w-[356px]'>{children}</div>
        </Card.Root>
      </div>
    </div>
  )
}

export default GuestWrapper
