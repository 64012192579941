import clsx from 'clsx'
import {useMemo} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useMasterLayout} from 'src/app/context/MasterContext'
import ApplicationScreens from 'src/app/modules/application/Screens'
import ClientScreens from 'src/app/modules/client/Screens'
import DashboardScreens from 'src/app/modules/dashboard/Screens'
import UserRoleScreens from 'src/app/modules/permissions/Screens'
import {ModulePermissionQuery} from 'src/app/modules/permissions/models/Role.model'
import {User} from 'src/app/modules/permissions/models/User.model'
import {RootState} from 'src/setup'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  module_permissions?: ModulePermissionQuery
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  hidden?: boolean
  disabled?: boolean
}

const useMenus = (user?: User): Menu[] => {
  return useMemo(
    () =>
      [
        {
          children: [
            {
              to: DashboardScreens.DASHBOARD.PATH,
              icon: '/media/icons/IconDashboard.svg',
              title: DashboardScreens.DASHBOARD.TITLE,
              module_permissions: DashboardScreens.DASHBOARD.PERMISSION,
            },
            {
              to: ApplicationScreens.APPLICATION.PATH,
              icon: '/media/icons/IconDocumentCheck.svg',
              title: ApplicationScreens.APPLICATION.TITLE,
              hidden: !user?.is_superadmin,
              children: [
                {
                  to: ApplicationScreens.LIST_APPLICATION.PATH,
                  activePath: ApplicationScreens.LIST_APPLICATION.PATH,
                  title: ApplicationScreens.LIST_APPLICATION.TITLE_ALT,
                  hasBullet: true,
                },
                {
                  to: ApplicationScreens.LIST_VERSION.PATH,
                  activePath: ApplicationScreens.LIST_VERSION.PATH,
                  title: ApplicationScreens.LIST_VERSION.TITLE_ALT,
                  hasBullet: true,
                },
              ],
            },
            {
              to: UserRoleScreens.USER_ROLE.PATH,
              icon: '/media/icons/IconUser.svg',
              title: UserRoleScreens.USER_ROLE.TITLE,
              children: [
                {
                  to: UserRoleScreens.LIST_USER.PATH,
                  activePath: UserRoleScreens.LIST_USER.PATH,
                  title: UserRoleScreens.LIST_USER.TITLE,
                  hasBullet: true,
                  module_permissions: UserRoleScreens.LIST_USER.PERMISSION,
                },
                {
                  to: UserRoleScreens.LIST_ROLE.PATH,
                  activePath: UserRoleScreens.LIST_ROLE.PATH,
                  title: UserRoleScreens.LIST_ROLE.TITLE,
                  hasBullet: true,
                  module_permissions: UserRoleScreens.LIST_ROLE.PERMISSION,
                },
              ],
            },
            {
              to: ClientScreens.CLIENT.PATH,
              icon: '/media/icons/IconUserMore.svg',
              title: ClientScreens.CLIENT.TITLE,
              children: [
                {
                  to: ClientScreens.LIST_ORGANIZATION.PATH,
                  activePath: ClientScreens.LIST_ORGANIZATION.PATH,
                  title: ClientScreens.LIST_ORGANIZATION.TITLE,
                  hasBullet: true,
                },
                {
                  to: ClientScreens.LIST_USER.PATH,
                  activePath: ClientScreens.LIST_USER.PATH,
                  title: ClientScreens.LIST_USER.TITLE,
                  hasBullet: true,
                  module_permissions: ClientScreens.LIST_USER.PERMISSION,
                },
                // {
                //   to: ClientScreens.LIST_ROLE.PATH,
                //   activePath: ClientScreens.LIST_ROLE.PATH,
                //   title: ClientScreens.LIST_ROLE.TITLE,
                //   hasBullet: true,
                //   module_permissions: ClientScreens.LIST_ROLE.PERMISSION,
                // },
                {
                  to: ClientScreens.LIST_MEMBER.PATH,
                  activePath: ClientScreens.LIST_MEMBER.PATH,
                  title: ClientScreens.LIST_MEMBER.TITLE,
                  hasBullet: true,
                  module_permissions: ClientScreens.LIST_MEMBER.PERMISSION,
                },
              ],
            },
          ],
        },
      ] as Menu[],
    [user]
  )
}

export const GeneratedMenu: React.FC<{menu: Menu}> = ({menu}) => {
  const {minimize} = useMasterLayout()

  if (!menu.to) {
    if (!menu.children || menu.children.length === 0) return null
    return (
      <>
        <div className='block p-0'>
          <div className='px-6 pt-8 pb-2'>
            <span
              className={clsx('tracking-widest text-white uppercase text-fs-10', {
                hidden: minimize,
              })}
            >
              {menu.title}
            </span>
          </div>
        </div>
        {menu.children?.map((child) => {
          return <GeneratedMenu menu={child} key={child.id} />
        })}
      </>
    )
  }
  if (!menu.children)
    return (
      <AsideMenuItem
        to={menu.to}
        icon={menu.icon}
        title={menu.title}
        fontIcon={menu.fontIcon}
        hasBullet={menu.hasBullet}
        activePath={menu.activePath}
        badge={menu.badge}
        badgeCircle={menu.badgeCircle}
        disabled={menu.disabled}
      />
    )

  return (
    <AsideMenuItemWithSub
      to={menu.to}
      title={menu.title}
      fontIcon={menu.fontIcon}
      icon={menu.icon}
      activePath={menu.activePath}
      hasBullet={menu.hasBullet}
      disabled={menu.disabled}
    >
      {menu.children.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </AsideMenuItemWithSub>
  )
}

const filterMenus = (
  menus: Menu[] | undefined,
  predicate: (menu: Menu) => boolean
): Menu[] | undefined => {
  const result = menus?.map((menu, index) => ({
    ...menu,
    id: String(index),
    children: filterMenus(menu.children, predicate),
  }))
  return result?.filter((menu) => (!menu.children || menu.children.length > 0) && predicate(menu))
}

const AsideMenuData: React.FC = () => {
  const user: User = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as User
  const menus = useMenus(user)
  // const {hasAccess} = usePermissions()

  // const generated = useMemo(
  //   () =>
  //     filterMenus(menus, (menu) => hasAccess(menu?.module_permissions) && !Boolean(menu.hidden)),
  //   [menus, hasAccess]
  // )

  const generated = useMemo(() => filterMenus(menus, (menu) => !menu.hidden), [menus])
  return (
    <>
      {generated?.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </>
  )
}

export default AsideMenuData
